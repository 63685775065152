import { createApp } from "vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { createPinia } from "pinia";
import App from "./App.vue";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { initDefaultFirebaseApp } from "./plugins/firebase";
import { AxiosResponse } from "axios";
import axios from "axios";
import { useLoginStore } from "@/stores/loginStore";
import { useSnackbarStore } from "@/stores/snackbarStore";

const pinia = createPinia();
const app = createApp(App);

const { initializeStore, initialiseCurrentUser } = useLoginStore(pinia);
const { showAlertSnackbar } = useSnackbarStore(pinia);

async function initializeStoreHelper(): Promise<AxiosResponse<any, any> | undefined> {
  const envEndpoint = process.env.VUE_APP_API_URL + "/autoconfig/apiconfig.json";
  try {
    const response = await axios.get(envEndpoint);
    if (response.data.apiBaseUrl) {
      initializeStore();
    }
    return response;
  } catch (error: any) {
    showAlertSnackbar(error.message);
  }
}

async function initApp(): Promise<void> {
  const response = await initializeStoreHelper();
  await initDefaultFirebaseApp(response?.data.apiKey, response?.data.authDomain, response?.data.gcpProjectId);
  await initialiseCurrentUser();
  app.mount("#app");
}

app.use(vuetify);
app.use(pinia);
app.use(router);
initApp();
