import { defineStore } from "pinia";
import { LoginData, UserData } from "@/types";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useSnackbarStore } from "@/stores/snackbarStore";

export const useLoginStore = defineStore("loginStore", {
  state: () => ({
    loginData: {
      login: "",
      password: "",
    } as LoginData,
    userData: {
      loggedIn: false,
      apiUrl: "",
      coreUrl: "",
      jwtToken: "",
      id: "",
      roles: [],
      data: {
        displayName: "",
        photoURL: "",
        email: "",
        refreshToken: "",
      },
    } as UserData,
    sideMenu: {
      sidebarMenu: false,
    },
  }),
  actions: {
    initializeStore(): void {
      if (localStorage.getItem("ongageUser")) {
        const ongageUser = JSON.parse(localStorage.getItem("ongageUser") || "");
        this.userData.loggedIn = true;
        this.userData.data.displayName = ongageUser.displayName;
        this.userData.data.photoURL = ongageUser.photoURL;
        this.userData.data.email = ongageUser.email;
        this.userData.jwtToken = localStorage.getItem("ongageUsertoken") || "";
        this.userData.id = ongageUser.uid;
      }
    },
    fetchUser(user: firebase.User | null): void {
      this.userData.loggedIn = user !== null;
      if (user) {
        this.userData.data.displayName = user.displayName || "";
        this.userData.data.email = user.email || "";
        this.userData.data.refreshToken = user.refreshToken || "";
        this.userData.data.photoURL = user.photoURL || "";
        localStorage.setItem("ongageUser", JSON.stringify(user));
      } else {
        this.clearUserData();
      }
    },
    setUserRoles(roles: string[]): void {
      localStorage.setItem("ongageUserRoles", JSON.stringify(roles));
      this.userData.roles = roles;
    },
    setUserToken(token: string): void {
      localStorage.setItem("ongageUserToken", token);
      this.userData.jwtToken = token;
    },
    clearUserData(): void {
      this.userData.loggedIn = false;
      this.userData.apiUrl = "";
      this.userData.coreUrl = "";
      this.userData.jwtToken = "";
      this.userData.id = "";
      this.userData.roles = [];
      this.userData.data.displayName = "";
      this.userData.data.email = "";
      this.userData.data.refreshToken = "";
      this.userData.data.photoURL = "";
    },
    async logOutUser(): Promise<void> {
      try {
        await firebase.auth().signOut();
        localStorage.clear();
        this.clearUserData();
      } catch (error: any) {
        const { showAlertSnackbar } = useSnackbarStore();
        showAlertSnackbar(error.message);
      }
    },
    async logInUser(): Promise<void> {
      try {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        firebase.auth().tenantId = process.env.VUE_APP_TENANT_ID;
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          hd: "harlemnext.com",
        });
        const result = await firebase.auth().signInWithEmailAndPassword(this.loginData.login, this.loginData.password);
        this.fetchUser(result.user);
        this.storeUserToken();
      } catch (error: any) {
        const { showAlertSnackbar } = useSnackbarStore();
        showAlertSnackbar(error.message);
      }
    },
    async storeUserToken(): Promise<void> {
      const usertoken = (await firebase.auth().currentUser?.getIdToken(true)) || "";
      this.setUserToken(usertoken);
    },
    getCurrentUser(): Promise<firebase.User | null> {
      return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
          unsubscribe();
          resolve(user);
        }, reject);
      });
    },
    async initialiseCurrentUser(): Promise<void> {
      const user = await this.getCurrentUser();
      this.fetchUser(user);
    },
    checkIsEmail(text: string): boolean {
      return /.+@.+\..+/.test(text);
    },
  },
});
