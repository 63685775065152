import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

export async function initDefaultFirebaseApp(
  apiKey: string,
  authDomain: string,
  gcpProjectId: string
): Promise<firebase.app.App> {
  const firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: gcpProjectId,
  };

  return firebase.initializeApp(firebaseConfig);
}
