import { defineStore } from "pinia";
import { SnackbarStoreType } from "@/types";

export const useSnackbarStore = defineStore("snackbarStore", {
  state: () => ({
    snackbar: {
      openSnackbar: false,
      text: "",
      color: "",
      icon: "",
    } as SnackbarStoreType,
  }),
  actions: {
    showSuccessSnackbar(text: string): void {
      this.snackbar.text = text;
      this.snackbar.openSnackbar = true;
      this.snackbar.color = "teal";
      this.snackbar.icon = "mdi-party-popper";
    },
    showAlertSnackbar(text: string): void {
      this.snackbar.text = text;
      this.snackbar.openSnackbar = true;
      this.snackbar.color = "red-accent-2";
      this.snackbar.icon = "mdi-alert-rhombus-outline";
    },
  },
});
