import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/LoginOngage.vue"),
  },
  {
    path: "/ongage-campaigns",
    name: "ongage-campaigns",
    component: () => import(/* webpackChunkName: "OngageCampaigns" */ "@/views/OngageCampaigns.vue"),
    meta: {
      description: "ongage-campaigns",
      requiresAuth: true,
    },
  },
  {
    path: "/add-new-campaign",
    name: "add-new-campaign",
    component: () => import(/* webpackChunkName: "AddNewCampaign" */ "@/views/AddNewCampaign.vue"),
    meta: {
      description: "add-new-campaign",
      requiresAuth: true,
    },
  },
  {
    path: "/edit-campaign/:name",
    name: "edit-campaign",
    component: () => import(/* webpackChunkName: "AddNewCampaign" */ "@/views/AddNewCampaign.vue"),
    meta: {
      description: "edit-campaign",
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
